import { CURSOR_SIZE } from '../config/window.config'

export function getPopoverPositionInWindow(popoverId: string, popoverPositionY: number, popoverPositionX: number) {
  const cursorSpace = CURSOR_SIZE / 2
  const initialPosition = {
    Y: `${popoverPositionY + cursorSpace}px`,
    X:  `${popoverPositionX + cursorSpace}px`
  }
  const popoverElement = document.getElementById(popoverId)

  const maxPositionY = window.innerHeight - (popoverElement?.offsetHeight || 0)
  const maxPositionX = window.innerWidth - (popoverElement?.offsetWidth || 0)

  const { isPopoverInY, isPopoverInX } = isPopoverInWindowViewport(popoverPositionY, popoverPositionX, maxPositionY, maxPositionX)

  return {
    top: isPopoverInY ? initialPosition.Y : `${maxPositionY - cursorSpace}px`,
    left: isPopoverInX ? initialPosition.X : `${maxPositionX - cursorSpace}px`
  }
}

function isPopoverInWindowViewport(popoverPositionY: number, popoverPositionX: number, maxPositionY: number, maxPositionX: number) {
  return {
    isPopoverInY: popoverPositionY < maxPositionY,
    isPopoverInX: popoverPositionX < maxPositionX
  }
}