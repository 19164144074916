export enum FieldConditionOperator {
  EQUALS = 'equals',
  STRICT_EQUALS = 'strict_equals',
  NOT_EQUALS = 'not_equals',
  STRICT_NOT_EQUALS = 'strict_not_equals',
  SUPERIOR = 'superior',
  SUPERIOR_EQUALS = 'superior_equals',
  INFERIOR = 'inferior',
  INFERIOR_EQUALS = 'inferior_equals',
  IN = 'in',
  NOT_IN = 'not_in',
  ALL = 'all',
  EXISTS = 'exists',
  CONTAINS = 'contains',
}
