import { LatLng } from '@/features/opportunity/@types/Opportunity'
import type PinGmapProvider from './pinGmap.provider'
import { Nullable } from '@/features/@types/Nullable'

class PinMapService {
  #adapter: PinGmapProvider
  #pins: google.maps.marker.AdvancedMarkerElement[] = []

  constructor(adapter: PinGmapProvider) {
    this.#adapter = adapter
  }

  create(position: LatLng, content: Node, id: string | number) {
    const marker = this.#adapter.createPin(position, content)
    marker.id = id.toString()
    if (marker.content) (marker.content as HTMLElement).dataset.id = id.toString()
    
    this.#pins.push(marker)
    marker.addListener('click', () => { })

    return marker
  }

  getExistingPinById(id: Nullable<string | number>) {
    if (!id) return null

    return this.#pins.find(marker => marker.id === id.toString())
  }

  setMap(marker: google.maps.marker.AdvancedMarkerElement) {
    return this.#adapter.setPinMap(marker)
  }

  removePinsFromMap() {
    return this.#adapter.removePinsFromMap(this.#pins)
  }

  handleMouseOver(marker: google.maps.marker.AdvancedMarkerElement, callback: Function) {
    this.#adapter.setMouseOverListener(marker, callback)
  }
  
  handleMouseMove(marker: google.maps.marker.AdvancedMarkerElement, callback: Function) {
    this.#adapter.setMouseMoveListener(marker, callback)
  }

  handleMouseOut(marker: google.maps.marker.AdvancedMarkerElement, callback: Function) {
    this.#adapter.setMouseOutListener(marker, callback)
  }

  handleMouseClick(marker: google.maps.marker.AdvancedMarkerElement, callback: Function) {
    this.#adapter.setMouseClickListener(marker, callback)
  }
}

export default PinMapService
