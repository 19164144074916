import axios from 'axios'
import { FieldConditionOperator } from '../opportunity/@types/Operator'

class MockAdapterClass {
  static async GET<T>(params: string): Promise<T> {
    if (params === '/layers') {
      const response = {
        country: 'US',
        layers: [
          {
            breakpoint: 7,
            level: 1,
            type: 'level1',
            style: {
              base: {
                fillColor: '#C6F2B8',
                fillOpacity: 0.7,
                strokeOpacity: 1,
                strokeWeight: 1,
                strokeColor: '#C6F2B8',
              },
              rules: [
                {
                  label: {
                    en: 'No opportunity',
                  },
                  property: 'totalValue',
                  operator: FieldConditionOperator.EQUALS,
                  comparator: '0',
                  style: {
                    fillColor: '#C6F2B8',
                    strokeColor: '#C6F2B8',
                  },
                },
                {
                  label: {
                    en: 'Low Business Opportunity',
                  },
                  property: 'totalValue',
                  operator: FieldConditionOperator.INFERIOR,
                  comparator: '100000000',
                  style: {
                    fillColor: '#89C697',
                    strokeColor: '#89C697',
                  },
                },
                {
                  label: {
                    en: 'Medium Business Opportunity',
                  },
                  property: 'totalValue',
                  operator: FieldConditionOperator.INFERIOR,
                  comparator: '500000000',
                  style: {
                    fillColor: '#33895E',
                    strokeColor: '#1A5135',
                  },
                },
                {
                  label: {
                    en: 'High Business Opportunity',
                  },
                  property: 'totalValue',
                  operator: FieldConditionOperator.SUPERIOR,
                  comparator: '500000000',
                  style: {
                    fillColor: '#02321F',
                    strokeColor: '#02321F',
                  },
                },
              ],
            },
          },
          {
            level: 2,
            type: 'level2',
            style: {
              base: {
                fillColor: '#C6F2B8',
                fillOpacity: 0.7,
                strokeOpacity: 1,
                strokeWeight: 1,
                strokeColor: '#C6F2B8',
              },
              rules: [
                {
                  label: {
                    en: 'No opportunity',
                  },
                  property: 'totalValue',
                  operator: FieldConditionOperator.EQUALS,
                  comparator: '0',
                  style: {
                    fillColor: '#C6F2B8',
                    strokeColor: '#C6F2B8',
                  },
                },
                {
                  label: {
                    en: 'Low Business Opportunity',
                  },
                  property: 'totalValue',
                  operator: FieldConditionOperator.INFERIOR,
                  comparator: '1000000',
                  style: {
                    fillColor: '#89C697',
                    strokeColor: '#89C697',
                  },
                },
                {
                  label: {
                    en: 'Medium Business Opportunity',
                  },
                  property: 'totalValue',
                  operator: FieldConditionOperator.INFERIOR,
                  comparator: '10000000',
                  style: {
                    fillColor: '#33895E',
                    strokeColor: '#1A5135',
                  },
                },
                {
                  label: {
                    en: 'High Business Opportunity',
                  },
                  property: 'totalValue',
                  operator: FieldConditionOperator.SUPERIOR,
                  comparator: '10000000',
                  style: {
                    fillColor: '#02321F',
                    strokeColor: '#02321F',
                  },
                },
              ],
            },
          },
          // { breakpoint: 10, uri: '/postalCode/us/opportunities' } To illustrate breakpoint possibility
        ],
      }

      return response as T
    }

    if (params === '/pins') {
      const response = {
        country: 'US',
        configuration: {
          style: {
            base: {
              color: '#2e2d2d',
            },
            rules: [
              {
                label: {
                  en: 'Trending down',
                },
                property: 'trend',
                operator: FieldConditionOperator.EQUALS,
                comparator: 'decreasing',
                style: {
                  color: '#EF3124',
                },
              },
              {
                label: {
                  en: 'Trending up',
                },
                property: 'trend',
                operator: FieldConditionOperator.EQUALS,
                comparator: 'raising',
                style: {
                  color: '#53B027',
                },
              },
              {
                label: {
                  en: 'Not owned',
                },
                property: 'trend',
                operator: FieldConditionOperator.EQUALS,
                comparator: 'notOwned',
                style: {
                  color: '#5B99F6',
                },
              },
              {
                label: {
                  en: 'No buy',
                },
                property: 'trend',
                operator: FieldConditionOperator.EQUALS,
                comparator: 'noBuy',
                style: {
                  color: '#000000',
                },
              },
            ]
          }
        }
      }

      return response as T
    }


    if (params.startsWith('/retailers')) {
      const retailersPath = process.env.NODE_ENV !== 'production' ? 'https://gtr-opportunity-preprod.s3.eu-west-3.amazonaws.com/US/retailers.json' : 'https://gtr-opportunity.s3.eu-west-3.amazonaws.com/US/retailers.json'
      await new Promise(resolve => setTimeout(resolve, 1000))

      const retailers = await axios.get(retailersPath)

      return {
        rows: retailers.data,
      } as T
    }

    return null as T
  }
}

export default MockAdapterClass
