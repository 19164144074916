import { buildComparison } from '../../../utils/operator.util'
import { DivisionIntention } from '../../../@types/Opportunity'
import { FieldConditionOperator } from '../../../@types/Operator'
import {
  ConditionalStyle,
  ConditionalStyleProperties,
  Levels,
  Properties,
  Style,
} from '../../../features/layer/@types/layer'
import GmapProvider from '../gmap.provider'
import { GmapEvent } from '../@types/Event'

export const GEOJSON_ID = 'id'

class LayerGmapProvider {
  #adapter: GmapProvider

  constructor(adapter: GmapProvider) {
    this.#adapter = adapter
  }

  createLayer() {
    return new google.maps.Data()
  }

  setLayerOnMap(layer: google.maps.Data) {
    const map = this.#adapter.getMap()

    layer.setMap(map)
  }

  hideLayersOnMap(layers: Map<Levels, google.maps.Data>) {
    for (const [keys, layer] of layers) {
      layer.setMap(null)
    }
  }

  setLayerStyle(layer: google.maps.Data, style: Style, intentions: DivisionIntention[]) {
    layer.setStyle(geojson => {
      let styleBase = { ...style.base }
      const geoJsonId = geojson.getProperty(GEOJSON_ID)
      const intention = intentions.find(intention => intention.divisionId === geoJsonId)

      if (!intention) return style.base

      styleBase = {
        ...styleBase,
        ...LayerGmapProvider.getGeojsonStylePerRules(style.rules, {
          totalCount: intention.totalCount,
          totalValue: intention.totalValue,
        }),
      }

      return styleBase
    })
  }

  static getGeojsonStylePerRules(rules: ConditionalStyle[], properties: ConditionalStyleProperties) {
    const operatorStopIteration = [
      FieldConditionOperator.INFERIOR,
      FieldConditionOperator.INFERIOR_EQUALS,
      FieldConditionOperator.EQUALS,
      FieldConditionOperator.STRICT_EQUALS,
    ]
    let style: Properties = {}

    for (const { property, operator, comparator, style: ruleStyle } of rules) {
      const propertyToCompare = properties[property]

      if (buildComparison(propertyToCompare, operator, comparator)) {
        style = {
          ...style,
          ...ruleStyle,
        }

        if (operatorStopIteration.includes(operator)) break
      }
    }

    return style
  }

  setMouseOverListener(layer: google.maps.Data, callback: Function) {
    layer.addListener(GmapEvent.MOUSE_OVER, callback)
  }

  setMouseMoveListener(layer: google.maps.Data, callback: Function) {
    layer.addListener(GmapEvent.MOUSE_MOVE, callback)
  }

  setMouseOutListener(layer: google.maps.Data, callback: Function) {
    layer.addListener(GmapEvent.MOUSE_OUT, callback)
  }

  setMouseClickListener(layer: google.maps.Data, callback: Function) {
    layer.addListener(GmapEvent.CLICK, callback)
  }
}

export default LayerGmapProvider
