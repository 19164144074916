import { Levels } from './@types/layer'
import Layer from './layer.entities'

class LayerStorage {
  private layers: Map<Levels, Layer>

  constructor() {
    this.layers = new Map()
  }

  add(layer: Layer): Layer {
    this.layers.set(layer.level, layer)
    return layer
  }

  get(level: Levels): Layer | undefined {
    return this.layers.get(level)
  }

  getAll(): Map<Levels, Layer> {
    return this.layers
  }

  clear(): void {
    this.layers = new Map()
  }
}

export default LayerStorage
