class SequentialTaskExecutor {
    private queue: Function[] = []
    private isRunning = false

    setQueue(fn: Function) {
        this.queue.push(fn)
    }

    async runQueue() {
        if (this.isRunning || this.queue.length === 0) return

        this.isRunning = true
        const nextInQueue = this.queue.shift()

        if (nextInQueue) await nextInQueue()

        this.isRunning = false
        this.runQueue()
    }
}

export default SequentialTaskExecutor
