import { HttpRepository } from '@/features/opportunity/api/repositories/http.repository'
import Layer from '../../features/layer/layer.entities'
import { DivisionIntention } from '../../@types/Opportunity'
import { Feature, FeatureCollection, GeoJsonProperties, Geometry } from 'geojson'
import { COUNTRY_CODE } from '../../@types/LocaleCode'
import { downloadFile } from '@/utils/utilities.util'
import { FileExtension } from '../../@types/File'
import { AxiosRequestConfig } from 'axios'
import { DivisionKey } from '../../features/layer/@types/layer'
import MockAdapterClass from '@/features/mocks/axios.mock'
import { Retailer } from '../../@types/retailer'

type IntentionsDto = {
  paginated: boolean
  total: number
  rows: DivisionIntention[]
}

type RetailersDto = {
  rows: Retailer[]
}

class OpportunityService {
  private _repository: HttpRepository

  constructor(repository: HttpRepository) {
    this._repository = repository
  }

  async getGeojsonsFeatureCollection(uri: string): Promise<FeatureCollection> {
    const geojson = await this._repository.GET<Feature<Geometry, GeoJsonProperties>[]>(uri)
    const featureCollection: FeatureCollection = {
      type: 'FeatureCollection',
      features: geojson,
    }

    return featureCollection
  }

  async getRetailers(country: COUNTRY_CODE) {
    const retailers = await MockAdapterClass.GET<RetailersDto>(`/retailers?country=${country}`)
    return retailers.rows
  }

  async getIntentions(type: DivisionKey, country: COUNTRY_CODE) {
    const intentions = await this._repository.GET<IntentionsDto>(`/intentions?country=${country}&division=${type}`)
    return intentions.rows
  }

  async getGeojsonsAndIntentions(layer: Layer, countryCode: COUNTRY_CODE) {
    const featureCollection = await this.getGeojsonsFeatureCollection(layer.geojsonUrl)
    const intentions = await this.getIntentions(layer.type, countryCode)
    return { featureCollection, intentions }
  }

  async downloadExport() {
    const config: AxiosRequestConfig = {
      responseType: 'arraybuffer',
    }

    try {
      const file = await this._repository.POST<string>('/intentions/export', {}, config)
      downloadFile(file, `${Date.now()}.${FileExtension.XLSX}`)
    } catch (error) {
      console.error('Error during download:', error)
    }
  }
}

export default OpportunityService
