import { User } from '@/features/@types/AppContext'
import { PROXY_URI } from '../@types/Proxy'
import { HttpRepository } from '../api/repositories/http.repository'
import OpportunityService from '../api/services/opportunity.service'
import ClientService from '../features/client/client.service'
import GmapProvider from '../features/map/gmap.provider'
import MapService from '../features/map/map.service'
import { provide } from 'vue'
import { PROVIDE_KEY } from '../@types/ProvideKey'

type AppContext = {
  currentUser: User
  clientId: string
}

export function useOpportunityModules(appContext: AppContext) {
  const mapAdapter = new GmapProvider()
  const mapService = new MapService(mapAdapter)

  const httpRepository = new HttpRepository(`${PROXY_URI.OPPORTUNITY}opportunity/clients/${appContext.clientId}`)
  const clientService = new ClientService(httpRepository)
  const opportunityService = new OpportunityService(httpRepository)

  provide(PROVIDE_KEY.OPPORTUNITY_HTTP_REPOSITORY, httpRepository)
  provide(PROVIDE_KEY.OPPORTUNITY_SERVICE, opportunityService)
  provide(PROVIDE_KEY.MAP_SERVICE, mapService)
  provide(PROVIDE_KEY.MAP_ADAPTER, mapAdapter)

  return {
    clientService,
    mapService,
    mapAdapter,
    opportunityService,
  }
}
