import { Nullable } from '@/features/@types/Nullable'
import { DivisionIntention } from './Opportunity'
import { Retailer, TrendStatus } from './retailer'
import { VIEW_MODE } from './Mode'

export type Popover = {
  show: boolean
  style: {
    top: string
    left: string
  }
  color: string
}

export type PopoverDivision = Popover & {
  intention: Nullable<DivisionIntention>
}

export type PopoverTypeMarker = Popover & {
  type: PopoverPinType.MARKER
  data: Nullable<Retailer>
}

export type PopoverTypeCluster = Popover & {
  type: PopoverPinType.CLUSTER
  data: Nullable<PopoverClusterData>
}

type PopoverClusterData = {
  count: number
  trends: {
    [key in TrendStatus]: number
  }
  annualRevenue: number
}

export type PopoverMarker = PopoverTypeMarker | PopoverTypeCluster

export enum PopoverPinType {
  MARKER = 'marker',
  CLUSTER = 'cluster',
}

type DrawerWith<T extends VIEW_MODE> = {
  mode: T
  show: boolean
  color: string
  data: T extends VIEW_MODE.PIN
    ? Nullable<Retailer>
    : T extends VIEW_MODE.DIVISIONS
    ? Nullable<DivisionIntention>
    : never
}

export type Drawer = DrawerWith<VIEW_MODE.PIN> | DrawerWith<VIEW_MODE.DIVISIONS>
