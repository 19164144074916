import { Nullable } from '@/features/@types/Nullable'
import { ObjectName } from './@types/object'
import Layer from '../features/layer/layer.entities'
import { Retailer } from '../@types/retailer'

const DATABASE_ID = 'opportunity-db'
const MS_BEFORE_TIMEOUT = 3000

class IndexedDB {
  static _instance: IndexedDB = new IndexedDB()
  #database: Nullable<IDBDatabase>

  open(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      if (this.#database) return

      const request = indexedDB.open(DATABASE_ID)

      request.onupgradeneeded = () => {
        const db = request.result
        if (!db.objectStoreNames.contains(ObjectName.LAYERS)) {
          db.createObjectStore(ObjectName.LAYERS, { keyPath: 'level' })
        }

        if (!db.objectStoreNames.contains(ObjectName.RETAILERS)) {
          db.createObjectStore(ObjectName.RETAILERS, { keyPath: 'id' })
        }
      }
  
      request.onsuccess = () => {
        this.#database = request.result
        resolve(request.result)
      }
  
      request.onerror = () => {
        reject(`Failed to open ${DATABASE_ID}`)
      }

      setTimeout(() => {
        reject(`Timed out trying to open ${DATABASE_ID}`);
      }, MS_BEFORE_TIMEOUT);
    })
  }

  clearDatabase() {
    this.#database?.close()
    this.#database = null
  }

  delete() {
    return new Promise((resolve, reject) => {
      this.clearDatabase()
      const request = indexedDB.deleteDatabase(DATABASE_ID)

      request.onsuccess = () => {
        resolve(request.readyState)
      }

      request.onerror = () => {
        reject(`Failed to delete ${DATABASE_ID}`)
      }

      setTimeout(() => {
        reject(`Timed out trying to delete ${DATABASE_ID}`);
      }, MS_BEFORE_TIMEOUT)
    })
  }

  getTransaction(name: string) {
    if (!this.#database) throw new Error('Database is not opened')

    return this.#database.transaction(name, 'readwrite')
  }

  getObject(name: string, transaction: IDBTransaction) {
    return transaction.objectStore(name)
  }

  getAll<T>(name: ObjectName): Promise<T[]> {
    return new Promise(async (resolve) => {
      try {
        const transaction = this.getTransaction(name)
        const layerObject = this.getObject(name, transaction)
        const request: IDBRequest<T[]> = layerObject.getAll()

        request.onsuccess = () => {
          resolve(request.result)
        }

        request.onerror = () => {
          resolve([])
        }
      } catch (error) {
        console.error('Empty store object')
        resolve([])
      }
    })
  }

  addOrUpdate(object: Layer | Retailer, name: ObjectName) {
    const transaction = this.getTransaction(name)
    const layerObject = this.getObject(name, transaction)

    layerObject.put(object)
  }
}

export default IndexedDB._instance
