import { Levels } from '../features/layer/@types/layer'
import { UseDrawerReturnType } from './useDrawer.composable'
import { UsePopoverDivisionReturnType } from './usePopoverDivision.composable'
import { getPopoverPositionInWindow } from '../utils/popover.util'
import { overrideStyle, STYLE } from '../utils/mapsFeature.utils'
import { GEOJSON_ID } from '../features/map/layer/layerGmap.provider'
import { LEVEL_KEY } from '../features/map/layer/layerMap.service'
import type Layer from '../features/layer/layer.entities'
import type { UseLayerReturnType } from './useLayer.composable'
import { VIEW_MODE } from '../@types/Mode'

export function useLayerInteractions(
  useLayer: UseLayerReturnType,
  usePopoverDivision: UsePopoverDivisionReturnType,
  useDrawer: UseDrawerReturnType,
  emit: (event: any, ...args: any[]) => void
) {
  const { drawer, setDrawerProperties, openDrawer } = useDrawer
  const { popover, closePopover, setPopoverProperties } = usePopoverDivision

  function getDrawerProperties(geojsonId: string, layer: Layer) {
    const layerData = useLayer.getLayerData(geojsonId, layer.intentions)
    const layerStyle = useLayer.getLayerStyle(layer.style.rules, { totalCount: layerData?.totalCount ?? 0, totalValue: layerData?.totalValue ?? 0 })

    return { layerData, layerStyle }
  }

  function onLayerMouseClick(event: google.maps.Data.MouseEvent, mapLayer: google.maps.Data) {
    const geojsonId = event.feature.getProperty(GEOJSON_ID) as string
    const level: Levels = mapLayer.get(LEVEL_KEY)
    const clientLayer = useLayer.layerService.getLayer(level)
    if (!clientLayer) return
    emit('toggleAppDrawer', false)

    const { layerData, layerStyle } = getDrawerProperties(geojsonId, clientLayer)
    setDrawerProperties(layerData, layerStyle.fillColor)
    mapLayer.revertStyle()
    overrideStyle(mapLayer, event.feature, STYLE.SELECT)

    openDrawer()
  }
  
  function onLayerMouseMove(event: google.maps.Data.MouseEvent) {
    const { pageY, pageX } = event.domEvent as MouseEvent
    const { top, left } = getPopoverPositionInWindow('popover-geojson', pageY, pageX)
  
    popover.value.style.top = top
    popover.value.style.left = left
    popover.value.show = true
  }
  
  function onLayerMouseOut(event: google.maps.Data.MouseEvent, mapLayer: google.maps.Data) {
    const geojsonId = event.feature.getProperty(GEOJSON_ID) as string
  
    closePopover()

    if (drawer.value.mode === VIEW_MODE.DIVISIONS) {
      const openInsideDrawer = geojsonId === drawer.value.data?.divisionId
      if (!openInsideDrawer) mapLayer.revertStyle(event.feature)
    }
  }
  
  function onLayerMouseOver(event: google.maps.Data.MouseEvent, mapLayer: google.maps.Data) {
    const geojsonId = event.feature.getProperty(GEOJSON_ID) as string
    const level: Levels = mapLayer.get(LEVEL_KEY)
    const clientLayer = useLayer.layerService.getLayer(level)
    if (!clientLayer) return

    const { layerData, layerStyle } = getDrawerProperties(geojsonId, clientLayer)
    setPopoverProperties(layerData, layerStyle)
    if (drawer.value.mode === VIEW_MODE.DIVISIONS && geojsonId === drawer.value.data?.divisionId) return

    overrideStyle(mapLayer, event.feature)
  }

  return {
    getDrawerProperties,
    onLayerMouseClick,
    onLayerMouseMove,
    onLayerMouseOver,
    onLayerMouseOut
  }
}