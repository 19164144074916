import { ColorFormat } from '../@types/color'
import { TrendStatus } from '../@types/retailer'
import { parseStringToDom, toRgbCss } from './conversion.utils'
import { getRetailerColor } from './retailer.utils'

export const ACTIVE_MARKER_CLASS = 'active-marker'

export function createPinContentHtml(trend: TrendStatus) {
  const svg = `
    <svg style="transition: transform 150ms ease-out;" width="20" viewBox="0 0 29 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.5 26.3563C20.3729 25.7374 22.0947 24.6865 23.5156 23.2656C25.9067 20.8745 27.25 17.6315 27.25 14.25C27.25 10.8685 25.9067 7.62548 23.5156 5.23439C21.1245 2.8433 17.8815 1.5 14.5 1.5C11.1185 1.5 7.87548 2.8433 5.48439 5.23439C3.0933 7.62548 1.75 10.8685 1.75 14.25C1.75 17.6315 3.0933 20.8745 5.48439 23.2656L6.54505 22.205L5.48439 23.2656C6.90531 24.6865 8.62709 25.7374 10.5 26.3563V27.7734V40.5C10.5 42.7112 12.2888 44.5 14.5 44.5C16.7112 44.5 18.5 42.7112 18.5 40.5V27.7734V26.3563Z"
        fill="${toRgbCss(getRetailerColor(ColorFormat.RGB, trend))}"
        stroke="white"
        stroke-width="2"
      />
    </svg>
  `
  return parseStringToDom(svg, 'image/svg+xml')
}
