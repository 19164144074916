import { Levels } from './@types/layer'
import Layer from './layer.entities'
import LayerRepository from './layer.repository'

class LayerService {
  #repository: LayerRepository

  constructor(repository: LayerRepository) {
    this.#repository = repository
  }
 
  saveLayer(layer: Layer) {
    return this.#repository.add(layer)
  }

  saveLayers(layers: Layer[]) {
    const savedLayers = []
    for (const layer of layers) {
      savedLayers.push(this.#repository.add(layer))
    }

    return savedLayers
  }

  getLayer(level: Levels) {
    return this.#repository.get(level)
  }

  getLayers() {
    return this.#repository.getAll()
  }

  getLayerByZoom(zoom: number) {
    return this.#repository.getLayerByZoom(zoom)
  }

  getLayerZoomConstraints(level: Levels) {
    return this.#repository.findLayerConstraints(level)
  }

  clearStorage() {
    this.#repository.clearStorage()
  }
}

export default LayerService
