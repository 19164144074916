import { GOOGLE_MAPS_API_KEY } from '@/features/opportunity/config/google-keys.config'
import { GmapEvent } from './@types/Event'

const ZOOM_INIT = 0

class GmapProvider {
  private map: google.maps.Map | null = null
  #listeners: google.maps.MapsEventListener[] = []

  createScript(scriptId: string, elementId: string, options: google.maps.MapOptions): Promise<google.maps.Map | unknown> {
    return new Promise((resolve, reject) => {
      if (document.getElementById(scriptId)) {
        return resolve(this.initializeMap(elementId, options))
      }

      const googleMapsScript = document.createElement('script')
      googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}`
      googleMapsScript.id = scriptId
      googleMapsScript.async = true
      googleMapsScript.defer = true
  
      googleMapsScript.addEventListener('load', async () => {
        try {
          const map = await this.initializeMap(elementId, options)
          resolve(map)
        } catch (error) {
          reject(error)
        }
      })
  
      googleMapsScript.addEventListener('error', (error) => {
        reject(error)
      })
  
      document.body.appendChild(googleMapsScript)
    })
  }

  private async initializeMap(id: string, options: google.maps.MapOptions) {
    const [{ Map }] =
      await Promise.all([
        google.maps.importLibrary("maps") as Promise<google.maps.MapsLibrary>,
        google.maps.importLibrary("marker")
      ])

    this.map = new Map(
      document.getElementById(id) as HTMLElement,
      options
    )

    return Promise.resolve(this.map)
  }

  getMap(): google.maps.Map | null {
    return this.map
  }

  fitBounds(bounds: google.maps.LatLngBounds) {
    this.map?.fitBounds(bounds)
  }

  getMapZoom() {
    return this.map?.getZoom()
  }

  getMapBounds() {
    return this.map?.getBounds()
  }

  setZoom(zoom: number) {
    this.map?.setZoom(zoom)
  }

  setCenter(position: google.maps.LatLngLiteral) {
    this.map?.setCenter(position)
  }

  zoomIn() {
    let currentZoom = this.getMapZoom() || ZOOM_INIT
    
    this.map?.setZoom(++currentZoom)
  }

  zoomOut() {
    let currentZoom = this.getMapZoom() || ZOOM_INIT
    this.map?.setZoom(--currentZoom)
  }

  setZoomChangedListener(callback: Function) {
    const listener = this.map?.addListener(GmapEvent.ZOOM_CHANGED, callback)
    if (listener) this.#listeners.push(listener)
  }

  setBoundsChangedListener(callback: Function) {
    const listener = this.map?.addListener(GmapEvent.BOUNDS_CHANGED, callback)
    if (listener) this.#listeners.push(listener)
  }

  clearListener() {
    for (const listener of this.#listeners) {
      listener.remove()
    }
  }

  clearMapAndListener() {
    this.clearListener()
    this.map = null
  }
}

export default GmapProvider
