import { HttpRepository } from '../repositories/http.repository'
import { DivisionsSearchDto, RetailersSearchDto } from '../@types/search'
import { COUNTRY_CODE } from '../../@types/LocaleCode'
import { Retailer, RetailerType } from '../../@types/retailer'
import { DivisionsSuggestions, RetailerSuggestions } from '../../@types/Suggestion'
import { VIEW_MODE } from '../../@types/Mode'

type RetailersSuggestionsReduced = { [keyof in RetailerType]: { key: RetailerType, items: Retailer[]} }

type Params = {
  limit: number
  query: string
}

class SearchService {
  #_repository: HttpRepository
  #URI: { [key in VIEW_MODE]: string };

  constructor(repository: HttpRepository, country: COUNTRY_CODE) {
    this.#_repository = repository
    this.#URI = {
      [VIEW_MODE.DIVISIONS]: `/countries/${country}/divisions/search`,
      [VIEW_MODE.PIN]: `/retailers/search`
    }
  }

  async getSuggestions(mode: VIEW_MODE, input: string, country: COUNTRY_CODE) {
    const params = {
      countryCode: country,
      limit: 3,
      itemsPerPage: 6,
      query: input
    }

    const formattedSuggestions = await (mode === VIEW_MODE.DIVISIONS ? this.#getDivisionsOrderedByLevel(params) : this.#getRetailersOrderedByType(params))

    return formattedSuggestions
  }

  async #getDivisionsOrderedByLevel(params: Params) {
    const divisions = await this.#_repository.POST<DivisionsSearchDto>(this.#URI.divisions, params)
    
    const formattedSuggestions = Object.entries(divisions).map(division => {
      return {
        key: division[0],
        items: division[1]
      }
    })

    return formattedSuggestions as DivisionsSuggestions[]
  }

  async #getRetailersOrderedByType(params: Params): Promise<RetailerSuggestions[]> {
    const { rows: retailers } = await (this.#_repository.POST<RetailersSearchDto>(this.#URI.pin, params))

    const formattedSuggestions = retailers.reduce(
      (previousRetailers: RetailersSuggestionsReduced, retailer) => {
        const type = retailer.type
        if (!previousRetailers[type]) {
          previousRetailers[type] = { key: type, items: [] }
        }
  
        previousRetailers[type].items.push(retailer)
        return previousRetailers;
      }, {} as RetailersSuggestionsReduced
    )

    return Object.values(formattedSuggestions)
  }
}

export default SearchService
