import { FieldConditionOperator } from '../@types/Operator'

export function buildComparison(leftOperand: string | number, operator: string, rightOperand: string | number) {
  switch (operator) {
    case FieldConditionOperator.SUPERIOR: 
      return leftOperand > rightOperand
    case FieldConditionOperator.INFERIOR: 
      return leftOperand < rightOperand
    case FieldConditionOperator.SUPERIOR_EQUALS:
      return leftOperand >= rightOperand
    case FieldConditionOperator.INFERIOR_EQUALS:
      return leftOperand <= rightOperand
    case FieldConditionOperator.EQUALS:
      return leftOperand == rightOperand
    case FieldConditionOperator.STRICT_EQUALS:
      return leftOperand === rightOperand
    case FieldConditionOperator.NOT_EQUALS:
      return leftOperand != rightOperand
    case FieldConditionOperator.STRICT_NOT_EQUALS:
      return leftOperand !== rightOperand
  }
}
