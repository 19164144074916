import { computed, inject, Ref, ref, watchEffect } from 'vue'
import { SuggestionHandler } from '../@types/Suggestion'
import { HttpRepository } from '../api/repositories/http.repository'
import { PROVIDE_KEY } from '../@types/ProvideKey'
import SearchService from '../api/services/search.service'
import { COUNTRY_CODE } from '../@types/LocaleCode'
import { VIEW_MODE } from '../@types/Mode'

const initSuggestion = () => (
  {
    mode: VIEW_MODE.DIVISIONS,
    suggestions: [],
    isLoading: false,
    isOpen: false
  }
)
const suggestion = ref<SuggestionHandler>(initSuggestion())
const searchBox = ref<HTMLInputElement>()

export function useSuggestion(country: COUNTRY_CODE, setCurrentMode: () => VIEW_MODE) {
  const opportunityHttp = inject<HttpRepository>(PROVIDE_KEY.OPPORTUNITY_HTTP_REPOSITORY)!
  const searchService = new SearchService(opportunityHttp, country)

  watchEffect(() => {
    resetSuggestion()
    if (searchBox.value) searchBox.value.value = ''
    suggestion.value.mode = setCurrentMode()
  })

  function resetSuggestion() {
    suggestion.value.suggestions = []
    closeBox()
  }

  async function handleUserInput() {
    suggestion.value.isLoading = true

    const inputValue = searchBox.value?.value
    if (!inputValue) return resetSuggestion()
  
    suggestion.value.suggestions = await searchService.getSuggestions(suggestion.value.mode, inputValue, country)
    openBox()
  }

  function stopLoading() {
    suggestion.value.isLoading = false
  }

  function openBox() {
    suggestion.value.isOpen = true
    stopLoading()
  }

  function closeBox() {
    suggestion.value.isOpen = false
    stopLoading()
  }

  const hasSuggestions = computed(() => {
    const allSuggestions = suggestion.value.suggestions.flat()
    return allSuggestions.length > 0
  })

  return {
    searchBox,
    suggestion,
    hasSuggestions,
    handleUserInput,
    openBox,
    closeBox
  }
}
