export enum TrendStatus {
  RAISING = 'raising',
  DECREASE = 'decreasing',
  NO_BUY = 'noBuy',
  NOT_OWNED = 'notOwned',
}

export enum RetailerType {
  RETAIL_CHAIN = 'retailChain',
  RETAILER = 'retailer',
  UNKNOWN = 'unknown',
}

export enum PeriodRange {
  PREVIOUS = 'previous',
  CURRENT = 'current',
}
export enum PeriodUnit {
  MONTH = 'month',
}

export type RetailerDivision = {
  id: string
  name: string
  code: string
}

export type RetailerAddress = {
  street1?: string
  street2?: string
  city?: string
  postalCode?: string
}

export type RetailerErpInformationValue = {
  period: PeriodRange
  ordersVolume: number
  ordersValue: number
}

export type RetailerErpInformation = {
  periodUnit: PeriodUnit
  values: RetailerErpInformationValue[]
}

export type Retailer = {
  id: string
  name: string
  annualRevenue: number
  activity: string
  subActivity: string
  address: RetailerAddress
  countryCode: string
  coordinates: {
    latitude: number
    longitude: number
  }
  type: RetailerType
  owned: boolean
  subscriptionDate?: string
  salesAmount: number
  erpInformations: RetailerErpInformation
  trend: TrendStatus
}

export type RetailerFeature = {
  type: 'Feature'
  geometry: {
    type: 'Point'
    coordinates: number[]
  },
  id: string | number
  properties: RetailerFeatureProperties
}

export type RetailerFeatureProperties = {
  type: RetailerType,
  trend: TrendStatus,
  cluster: boolean
  point_count: number
  trends: {
    [key in TrendStatus]: number
  }
  annualRevenue: number
}
