export enum DocumentEvent {
  KEY_DOWN = 'keydown',
}

export enum Keyboard {
  ESCAPE = 'Escape',
}

export enum DEBOUNCE_MS {
  ZOOM = 300,
  SEARCH = 250,
}
