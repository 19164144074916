export enum ColorFormat {
  RGB = 'rgb',
  HEX = 'hex',
}

export type Rgb = {
  r: number
  g: number
  b: number
}
