import { ref, Ref } from 'vue'
import GmapProvider from '../../features/map/gmap.provider'
import { LatLng } from '../../@types/Opportunity'

class MapService {
  #SCRIPT_ID = 'SCRIPT-MAP'
  #adapter: GmapProvider
  ELEMENT_ID = 'ELEMENT-MAP'
  isLoading: Ref<boolean>

  constructor(provider: GmapProvider) {
    this.#adapter = provider
    this.isLoading = ref(false)
  }

  setLoading(isLoading: boolean) {
    this.isLoading.value = isLoading
  }

  createScript(options: google.maps.MapOptions): Promise<google.maps.Map | unknown> {
    return this.#adapter.createScript(this.#SCRIPT_ID, this.ELEMENT_ID, options)
  }

  setViewOnBounds(bounds: google.maps.LatLngBounds) {
    this.#adapter.fitBounds(bounds)
  }

  get map() {
    return this.#adapter.getMap()
  }

  get mapZoom() {
    return this.#adapter.getMapZoom()
  }

  get mapBounds() {
    return this.#adapter.getMapBounds()
  }

  setZoom(zoom: number) {
    this.#adapter.setZoom(zoom)
  }

  centerMapOnPosition(position: LatLng) {
    this.#adapter.setCenter(position)
  }

  zoomIn() {
    this.#adapter.zoomIn()
  }

  zoomOut() {
    this.#adapter.zoomOut()
  }

  handleZoomChange(callback: Function) {
    this.#adapter.setZoomChangedListener(callback)
  }

  handleBoundsChanged(callback: Function) {
    this.#adapter.setBoundsChangedListener(callback)
  }

  clearListener() {
    this.#adapter.clearListener()
  }

  clearMapAndListener() {
    this.#adapter.clearMapAndListener()
  }
}

export default MapService
