import { Nullable } from '@/features/@types/Nullable'
import { Levels } from './@types/layer'
import Layer from './layer.entities'
import LayerStorage from './layer.storage'

type ZoomConstraints = {
  min: Nullable<number>
  max: Nullable<number>
}

class LayerRepository {
  private storage: LayerStorage

  constructor() {
    this.storage = new LayerStorage()
  }

  add(layer: Layer) {
    this.storage.add(layer)
    return layer
  }

  get(level: Levels) {
    return this.storage.get(level)
  }

  getAll() {
    return this.storage.getAll()
  }

  getLayerByZoom(zoom: number) {
    const lastLayer = Array.from(this.getAll().values()).pop()
    for (const [key, layer] of this.getAll()) {
      if (layer.breakpoint && zoom < layer.breakpoint) return layer
    }

    return lastLayer
  }

  findLayerConstraints(level: Levels) {
    const layers = this.getAll()

    const constraints: ZoomConstraints = {
      max: null,
      min: null
    }

    let previousLayer = null
    for (const [key, layer] of layers) {
      if (layer.level === level) {
        constraints.min = previousLayer?.breakpoint ?? null
        constraints.max = layer.breakpoint ?? null
        break
      }

      previousLayer = layer
    }

    return constraints
  }

  clearStorage() {
    this.storage.clear()
  }
}

export default LayerRepository
