import { Nullable } from '@/features/@types/Nullable'

export const STYLE = {
  BASE: { strokeWeight: 6, strokeOpacity: 1, zIndex: 99 },
  SELECT: { zIndex: 90, strokeColor: '#FFFFFF' }
}

function getGeometry(feature: google.maps.Data.Feature) {
  return feature.getGeometry()
}

export function calculateFeatureBounds(feature: google.maps.Data.Feature) {
  const bounds = new google.maps.LatLngBounds()
  const geometry = getGeometry(feature)
  
  geometry?.forEachLatLng(function (latlng) {
    bounds.extend(latlng)
  })

  return bounds
}

export function findFeatureInsideLayer(featureId: string, mapLayer: Nullable<google.maps.Data>){
  if (!mapLayer) return

  let feature: Nullable<google.maps.Data.Feature>
  mapLayer.forEach((element) => {
    if (element.getProperty('id') !== featureId) return

    feature = element
  })

  return feature
}

export function overrideStyle(layer: google.maps.Data, feature: google.maps.Data.Feature, overwriteStyle?: Nullable<google.maps.Data.StyleOptions>) {
  const style = {
    ...STYLE.BASE,
    ...overwriteStyle
  }
  
  layer.overrideStyle(feature, style)
}
