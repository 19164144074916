export function debounce<F extends (...args: any[]) => any>(func: F, wait: number, cancelWhenCallTwice: boolean = false): (...args: Parameters<F>) => void {
  let timeout: ReturnType<typeof setTimeout> | undefined
  return function (this: ThisParameterType<F>, ...args: Parameters<F>) {
    const context = this;
    
    if (cancelWhenCallTwice && timeout) {
      clearTimeout(timeout)
      timeout = undefined
      return
    };

    if (!cancelWhenCallTwice) clearTimeout(timeout)
    
    timeout = setTimeout(() => {
      func.apply(context, args);
      timeout = undefined;
      clearTimeout(timeout)
    }, wait);
  }
}
