import { ref } from 'vue'
import { PopoverDivision } from '../@types/Overlay'
import { DivisionIntention } from '../@types/Opportunity'
import { Properties } from '../features/layer/@types/layer'
import { Nullable } from '@/features/@types/Nullable'

const initPopover = () => ({
  show: false,
  style: {
    top: '',
    left: '',
  },
  color: '',
  intention: null,
})

export type UsePopoverDivisionReturnType = ReturnType<typeof usePopoverDivision>

export function usePopoverDivision() {
  const popover = ref<PopoverDivision>(initPopover())

  function closePopover() {
    popover.value = initPopover()
  }

  function setPopoverProperties(intention: Nullable<DivisionIntention>, style?: Properties) {
    if (intention) popover.value.intention = intention
    popover.value.color = style?.fillColor || ''
  }

  return {
    popover,
    closePopover,
    setPopoverProperties,
  }
}
