import { Ref, ref, watchEffect } from 'vue'
import { Drawer } from '../@types/Overlay'
import { DivisionIntention } from '../@types/Opportunity'
import { Nullable } from '@/features/@types/Nullable'
import { Retailer } from '../@types/retailer'
import { VIEW_MODE } from '../@types/Mode'

const initDrawer = () => ({ mode: VIEW_MODE.DIVISIONS, show: false, color: '', data: null })

export type UseDrawerReturnType = ReturnType<typeof useDrawer>

export function useDrawer(currentMode: Ref<VIEW_MODE>) {
  const drawer = ref<Drawer>(initDrawer())

  watchEffect(() => {
    drawer.value.mode = currentMode.value
  })

  function closeDrawer(func?: () => void) {
    if (func instanceof Function) func()

    drawer.value = initDrawer()
  }

  function openDrawer() {
    drawer.value.show = true
  }

  function setDrawerProperties(data: Nullable<DivisionIntention | Retailer>, color?: string) {
    if (data) drawer.value.data = data
    drawer.value.color = color || ''
  }

  return {
    drawer,
    openDrawer,
    closeDrawer,
    setDrawerProperties,
  }
}
