import { ref } from 'vue'
import { PopoverMarker, PopoverPinType } from '../@types/Overlay'

export const POPOVER_CLUSTER_ID = 'popover-cluster'
export const POPOVER_PIN_ID = 'popover-pin'

const initPopover = () => ({
  type: PopoverPinType.CLUSTER,
  show: false,
  style: {
    top: '',
    left: '',
  },
  color: '',
  data: null
})

export type UsePopoverPinReturnType = ReturnType<typeof usePopoverPin>

export function usePopoverPin() {
  const popover = ref<PopoverMarker>(initPopover())

  function closePopover() {
    popover.value = initPopover()
  }

  function setPopoverProperties(data: PopoverMarker['data'], type: PopoverPinType, color: string) {
    popover.value.data = data
    popover.value.type = type
    popover.value.color = color
  }

  return {
    popover,
    closePopover,
    setPopoverProperties,
  }
}
