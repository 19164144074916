import VueRouter from 'vue-router'
import { VIEW_MODE } from '../@types/Mode'

export function isInPinMode(queryMode: string | (string | null)[]) {
  return queryMode === VIEW_MODE.PIN
}

export function isInDivisionsMode(queryMode: string | (string | null)[]) {
  return queryMode === VIEW_MODE.DIVISIONS
}

export function getRouteView(router: VueRouter) {
  return (router.currentRoute.query.view ?? '') as VIEW_MODE
}

export function setRouteView(router: VueRouter) {
  if ([VIEW_MODE.DIVISIONS, VIEW_MODE.PIN].includes(router.currentRoute.query.view as VIEW_MODE)) return

  router.replace({ name: 'PlatformOpportunity', query: { view: VIEW_MODE.DIVISIONS } })
}
