import { ColorFormat, Rgb } from '../@types/color';
import { Retailer, RetailerFeature, TrendStatus } from '../@types/retailer'

export function convertRetailerToFeaturePoint(retailer: Retailer): RetailerFeature {
  const trends = {
    [TrendStatus.RAISING]: 0,
    [TrendStatus.DECREASE]: 0,
    [TrendStatus.NOT_OWNED]: 0,
    [TrendStatus.NO_BUY]: 0,
  }

  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [retailer.coordinates.longitude, retailer.coordinates.latitude]
    },
    id: retailer.id,
    properties: {
      cluster: false,
      type: retailer.type,
      trend: retailer.trend,
      point_count: 1,
      trends,
      annualRevenue: retailer.annualRevenue,
    }
  }
}

export function getRetailerColor<T extends ColorFormat>(format: T, trend?: TrendStatus): T extends 'rgb' ? Rgb : string  {
  const colorStatus: Record<TrendStatus, { rgb: Rgb; hex: string }> = {
    [TrendStatus.RAISING]: {
      rgb: {
        r: 83,
        g: 176,
        b: 39
      },
      hex: '#53B027'
    },
    [TrendStatus.DECREASE]: {
      rgb: {
        r: 239,
        g: 49,
        b: 36
      },
      hex: '#EF3124'
    },
    [TrendStatus.NOT_OWNED]: {
      rgb: {
        r: 91,
        g: 153,
        b: 246
      },
      hex: '#5B99F6'
    },
    [TrendStatus.NO_BUY]: {
      rgb: {
        r: 0,
        g: 0,
        b: 0
      },
      hex: '#00000'
    }
  }

  const defaultColor = {
    rgb: {
      r: 0,
      g: 0,
      b: 0
    },
    hex: '#00000'
  }

  return (trend ? colorStatus[trend][format] : defaultColor[format] as unknown) as T extends 'rgb' ? Rgb : string;
}
