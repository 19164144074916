import Supercluster from 'supercluster'
import { RetailerFeature, TrendStatus } from '../@types/retailer'

export type UseClusterReturnType = ReturnType<typeof useCluster>

export function useCluster() {
  const superClusterIndex = new Supercluster({
    radius: 120,
    minPoints: 3,
    maxZoom: 12,
    map: (properties) => {
      const trends = {
        ...properties.trends,
        [properties.trend]: 1
      }

      return { trends, annualRevenue: properties.annualRevenue || 0 }
    },
    reduce: (accumulated, properties) => {
      Object.values(TrendStatus).forEach(trend => {
        accumulated.trends[trend] += (properties.trends[trend] || 0)
        accumulated.annualRevenue += properties.annualRevenue
      })
    }
  })

  function generateClusters(features: RetailerFeature[]) {
    return superClusterIndex.load(features)
  }

  function getClustersFromViewport(bounds: google.maps.LatLngBounds, zoom: number): RetailerFeature[] {
    const [sw, ne] = [
      bounds.getSouthWest(),
      bounds.getNorthEast()
    ]

    return superClusterIndex.getClusters(
      [sw.lng(), sw.lat(), ne.lng(), ne.lat()],
      Math.floor(zoom)
    ) as RetailerFeature[]
  }

  function getClusterChildZoom(id: number | string) {
    if (typeof id !== 'number') return null

    return superClusterIndex.getClusterExpansionZoom(id)
  }

  return {
    superClusterIndex,
    generateClusters,
    getClustersFromViewport,
    getClusterChildZoom
  }
}