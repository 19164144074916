import MockAdapterClass from '@/features/mocks/axios.mock'
import { HttpRepository } from '../../api/repositories/http.repository'
import { CountryDto, LayerDto, LayersDto, PinDto } from './@types/dto'
import { ClientConfiguration } from '../../@types/Opportunity'
import { createClient } from './client.entities'
import { ClientLayer } from '../layer/@types/layer'
import { COUNTRY_CODE } from '../../@types/LocaleCode'

class ClientService {
  private _repository: HttpRepository

  constructor(repository: HttpRepository) {
    this._repository = repository
  }

  #layersWithGeojsonUrl(clientLayers: LayerDto[], divisions: CountryDto['divisions']) {
    const layers: ClientLayer[] = []

    for (const layer of clientLayers) {
      const clientLayer = {
        ...layer,
        geojsonUrl: divisions[layer.type].geojsonUrl,
      }

      layers.push(clientLayer)
    }

    return layers
  }

  async getClient() {
    const [countryGeojsons, clientConfiguration, layersConfiguration, pinsConfiguration] = await Promise.all([
      this._repository.GET<CountryDto>(`/countries/${COUNTRY_CODE.US}`),
      this._repository.GET<ClientConfiguration>(``),
      MockAdapterClass.GET<LayersDto>('/layers'),
      MockAdapterClass.GET<PinDto>('/pins')
    ])

    const layers = this.#layersWithGeojsonUrl(layersConfiguration.layers, countryGeojsons.divisions)

    return createClient({
      zoom: countryGeojsons.zoom,
      center: {
        lat: countryGeojsons.center.latitude,
        lng: countryGeojsons.center.longitude,
      },
      country: COUNTRY_CODE.US,
      mapId: clientConfiguration.map.id,
      layers: layers,
      pins: pinsConfiguration.configuration,
      monetization: clientConfiguration.monetization,
    })
  }
}

export default ClientService
