import { inject, ref } from 'vue'
import { ConditionalStyle, ConditionalStyleProperties } from '../features/layer/@types/layer'
import LayerRepository from '../features/layer/layer.repository'
import LayerService from '../features/layer/layer.service'
import { DivisionIntention } from '../@types/Opportunity'
import Layer from '../features/layer/layer.entities'
import LayerGmapProvider from '../features/map/layer/layerGmap.provider'
import LayerMapService, { LEVEL_KEY } from '../features/map/layer/layerMap.service'
import { MapAdapter } from '../features/map/@types/Adapter'
import { PROVIDE_KEY } from '../@types/ProvideKey'

export type UseLayerReturnType = ReturnType<typeof useLayer>

export function useLayer() {
  const mapAdapter = inject<MapAdapter>(PROVIDE_KEY.MAP_ADAPTER)!

  const currentMapLayer = ref<google.maps.Data>()
  const layerService = new LayerService(new LayerRepository())
  const layerMapService = new LayerMapService(new LayerGmapProvider(mapAdapter))

  function isLayerAlreadyOnMap(layer: Layer) {
    const layerLevelKey = currentMapLayer.value?.get(LEVEL_KEY)
    return layer[LEVEL_KEY] === layerLevelKey
  }

  function getLayerStyle(rules: ConditionalStyle[], styleProperties: ConditionalStyleProperties) {
    return LayerMapService.getLayerStyleBasedOnRules(rules, styleProperties)
  }

  function getLayerData(geojsonId: string, intentions: DivisionIntention[]) {
    return intentions.find(intention => intention.divisionId === geojsonId)
  }

  return {
    currentMapLayer,
    isLayerAlreadyOnMap,
    layerMapService,
    getLayerStyle,
    layerService,
    getLayerData,
  }
}
