import { LatLng } from '@/features/opportunity/@types/Opportunity'
import GmapProvider from '../gmap.provider'
import { GmapEvent } from '../@types/Event'

export const GEOJSON_ID = 'id'

class pinGmapProvider {
  #adapter: GmapProvider

  constructor(adapter: GmapProvider) {
    this.#adapter = adapter
  }

  createPin(position: LatLng, content: Node) {
    return new google.maps.marker.AdvancedMarkerElement({
      map: this.#adapter.getMap(),
      position: position,
      content,
      collisionBehavior: google.maps.CollisionBehavior.REQUIRED
    })
  }

  setPinMap(marker: google.maps.marker.AdvancedMarkerElement) {
    marker.map = this.#adapter.getMap()
    return marker
  }

  removePinsFromMap(markers: google.maps.marker.AdvancedMarkerElement[]) {
    for (const marker of markers) {
      marker.map = null
    }
  }

  setMouseOverListener(marker: google.maps.marker.AdvancedMarkerElement, callback: Function) {
    marker.content?.addEventListener(GmapEvent.MOUSE_OVER, callback as EventListenerOrEventListenerObject)
  }

  setMouseMoveListener(marker: google.maps.marker.AdvancedMarkerElement, callback: Function) {
    marker.content?.addEventListener(GmapEvent.MOUSE_MOVE, callback as EventListenerOrEventListenerObject)
  }

  setMouseOutListener(marker: google.maps.marker.AdvancedMarkerElement, callback: Function) {
    marker.content?.addEventListener(GmapEvent.MOUSE_OUT, callback as EventListenerOrEventListenerObject)
  }

  setMouseClickListener(marker: google.maps.marker.AdvancedMarkerElement, callback: Function) {
    marker.content?.addEventListener(GmapEvent.CLICK, callback as EventListenerOrEventListenerObject)
  }
}

export default pinGmapProvider
