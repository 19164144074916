import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

export class HttpRepository {
  protected api: AxiosInstance

  constructor(baseUrl: string) {
    this.api = axios.create({
      baseURL: baseUrl,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Authorization',
      },
    })
  }

  async GET<T>(uri: string, config?: AxiosRequestConfig): Promise<T> {
    try {
      const { data } = await this.api.get(uri, config)
      return data as T
    } catch (error: any) {
      throw error.response
    }
  }

  async POST<T>(uri: string, payload: NonNullable<unknown>, config?: AxiosRequestConfig): Promise<T> {
    try {
      const { data } = await this.api.post(uri, payload, config)
      return data as T
    } catch (error: any) {
      throw error.response
    }
  }
}
