import { Rgb } from '../@types/color'
import { DivisionKey, Levels } from '../features/layer/@types/layer'

export function divisionKeyToLevel(key: DivisionKey): Levels {
  return parseInt(key.split('level')[1])
}

export function parseStringToDom(content: string, type: DOMParserSupportedType) {
  return new DOMParser().parseFromString(content, type).documentElement
}

export function toRgbCss(rgb: Rgb) {
  const { r, g, b } = rgb
  return `rgb(${r}, ${g}, ${b})`
}

export function toRgbaCss(rgb: Rgb, opacity: number) {
  const { r, g, b } = rgb
  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}
