import { ColorFormat } from '../@types/color'
import { PopoverPinType } from '../@types/Overlay'
import { RetailerFeature } from '../@types/retailer'
import { MapAdapter } from '../features/map/@types/Adapter'
import pinGmapProvider from '../features/map/pin/pinGmap.provider'
import PinMapService from '../features/map/pin/pinMap.service'
import type MapService from '../features/map/map.service'
import type RetailerStorage from '../features/retailer/retailer.storage'
import { isFeatureCluster } from '../utils/cluster.utils'
import { getPopoverPositionInWindow } from '../utils/popover.util'
import { getRetailerColor } from '../utils/retailer.utils'
import { UseClusterReturnType } from './useCluster.composable'
import { POPOVER_CLUSTER_ID, POPOVER_PIN_ID, UsePopoverPinReturnType } from './usePopoverPin.composable'
import { UseDrawerReturnType } from './useDrawer.composable'
import { ACTIVE_MARKER_CLASS } from '../utils/marker.utils'
import { ref } from 'vue'

export function usePin(
  mapService: MapService,
  mapAdapter: MapAdapter, 
  retailerStorage: RetailerStorage, 
  usePopoverPin: UsePopoverPinReturnType,
  useDrawer: UseDrawerReturnType,
  useCluster: UseClusterReturnType
) {
  const activeMarker = ref<google.maps.marker.AdvancedMarkerElement>()
  const { getClusterChildZoom } = useCluster
  const { setDrawerProperties, openDrawer } = useDrawer
  const { popover, closePopover, setPopoverProperties } = usePopoverPin
  const pinMapService = new PinMapService(new pinGmapProvider(mapAdapter))

  function onPinMouseOver(retailerFeature: RetailerFeature) {
    if (isFeatureCluster(retailerFeature)) {
      const { point_count, trends, annualRevenue } = retailerFeature.properties
      setPopoverProperties({ count: point_count, trends, annualRevenue }, PopoverPinType.CLUSTER, '')
      return
    }
  
    const pin = pinMapService.getExistingPinById(retailerFeature.id)
    if (!pin?.id) return
  
    const retailer = retailerStorage.get(pin.id)
    setPopoverProperties(retailer, PopoverPinType.MARKER, getRetailerColor(ColorFormat.HEX, retailer?.trend))
  }
  
  function onPinMouseMove(domEvent: MouseEvent) {
    const popoverID = popover.value.type === PopoverPinType.CLUSTER ? POPOVER_CLUSTER_ID : POPOVER_PIN_ID
    const { pageY, pageX } = domEvent
    const { top, left } = getPopoverPositionInWindow(popoverID, pageY, pageX)
  
    popover.value.style.top = top
    popover.value.style.left = left
    popover.value.show = true
  }
  
  function onPinMouseOut() {
    closePopover()
  }
  
  function onPinClick(retailerFeature: RetailerFeature, pin: google.maps.marker.AdvancedMarkerElement) {
    if (!retailerFeature.properties.cluster) return markerClick(retailerFeature.id.toString(), pin)

    clusterClick(retailerFeature)
  }

  function clusterClick(retailerFeature: RetailerFeature) {
    if (!retailerFeature.properties.cluster) return
  

    const clusterChild = getClusterChildZoom(retailerFeature.id)
    if (!clusterChild) return

    const [lng, lat] = retailerFeature.geometry.coordinates
    mapService.setZoom(clusterChild)
    mapService.centerMapOnPosition({ lat, lng })
  }

  function markerClick(id: string, marker: google.maps.marker.AdvancedMarkerElement) {
    const retailer = retailerStorage.get(id)
    if (marker.content) setActiveMarkerStyle(marker)

    setDrawerProperties(retailer, getRetailerColor(ColorFormat.HEX, retailer?.trend))
    openDrawer()
  }

  function setActiveMarkerStyle(marker: google.maps.marker.AdvancedMarkerElement) {
    removeActiveMarkerStyle();

    (marker.content as HTMLElement).classList.add(ACTIVE_MARKER_CLASS)
    activeMarker.value = marker
  }

  function removeActiveMarkerStyle() {
    (activeMarker.value?.content as HTMLElement)?.classList?.remove(ACTIVE_MARKER_CLASS)
    activeMarker.value = undefined
  }

  return {
    pinMapService,
    onPinMouseOver,
    onPinMouseMove,
    onPinMouseOut,
    onPinClick,
    markerClick,
    setActiveMarkerStyle,
    removeActiveMarkerStyle
  }
}