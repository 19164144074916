import { Retailer } from '../../@types/retailer'

class RetailerStorage {
  #retailers: Map<string, Retailer>

  constructor() {
    this.#retailers = new Map()
  }

  add(retailer: Retailer): Retailer {
    this.#retailers.set(retailer.id, retailer)
    return retailer
  }

  get(id: string): Retailer | undefined {
    return this.#retailers.get(id.toString())
  }

  getAll(): Map<string, Retailer> {
    return this.#retailers
  }

  clear(): void {
    this.#retailers = new Map()
  }
}

export default RetailerStorage
