import { ColorFormat } from '../@types/color'
import { RetailerFeature, RetailerFeatureProperties, TrendStatus } from '../@types/retailer'
import { toRgbaCss, toRgbCss } from './conversion.utils'
import { getRetailerColor } from './retailer.utils'

export function createClusterContentHtml(trends: RetailerFeatureProperties['trends'], clusterSize: number) {
  const container = document.createElement('div')
  const color = createRGBBasedOnDominantColor(trends)

  const size = getClusterSizeinPx(clusterSize)
  const boxShadowCssSize = `calc(${size} / 6)`
  container.style.cssText = `
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    font-size: 14px;
    background-color: ${toRgbCss(color)};
    width: ${size};
    height: ${size};
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px ${boxShadowCssSize} ${toRgbaCss(color, 0.4)};
  `

  const textContainer = document.createElement('div')
  textContainer.style.cssText = `
    color: white;
    font-weight: 900;
    pointer-events: none;
  `
  textContainer.innerText = `${clusterSize}`

  container.appendChild(textContainer)
  return container
}

function getClusterSizeinPx(size: number) {
  if (size > 10000) return '90px'
  if (size > 5000) return '80px'
  if (size > 1000) return '70px'
  if (size > 500) return '60px'
  if (size > 100) return '50px'

  return '40px'
}

export function createRGBBasedOnDominantColor(trends: RetailerFeatureProperties['trends']) {
  const trendsTotal = Object.values(trends).reduce((sum, value) => sum + value, 0);
  const percentages = Object.fromEntries(
    Object.entries(trends).map(([key, value]) => [
      key,
      (value / trendsTotal) * 100
    ])
  )
  const [dominantKey, dominantValue] = Object.entries(percentages).reduce((max, current) => 
    current[1] > max[1] ? current : max
  )
  const isDominant = dominantValue > 80

  let r = 0, g = 0, b = 0;
  let totalWeight = 0;

  (Object.entries(percentages) as [TrendStatus, number][]).forEach(([key, percentage]) => {
      const baseColor = getRetailerColor(ColorFormat.RGB, key)
      let weight = Math.pow(percentage / 100, 3)
      
      if (isDominant && key === dominantKey) weight *= 2

      r += baseColor.r * weight
      g += baseColor.g * weight
      b += baseColor.b * weight
      totalWeight += weight
  })

  return {
    r: Math.round(r / totalWeight),
    g: Math.round(g / totalWeight),
    b: Math.round(b / totalWeight)
  }
}

export function isFeatureCluster(feature: RetailerFeature) {
  return feature.properties.cluster === true
}
