import { onMounted, onUnmounted } from 'vue'
import { DocumentEvent } from '../@types/Dom'

export function useKeyboardShortcuts(shortcuts: Record<string, () => void>) {

  function handleKeyDown(event: KeyboardEvent) {
    const handler = shortcuts[event.key]
    
    if (handler) {
      handler()
    }
  }

  onMounted(() => {
    document.addEventListener(DocumentEvent.KEY_DOWN, handleKeyDown)
  })

  onUnmounted(() => {
    document.removeEventListener(DocumentEvent.KEY_DOWN, handleKeyDown)
  })
}
